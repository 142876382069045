import clsx from "clsx"
import { animated, useSpring } from '@react-spring/web'
import { useState } from "react"
import { useChangedEffect } from "./utils"
import { easings } from '@react-spring/web'
import { B4Text, B4TextColor } from "./text"
import { B4IconButtonNoP } from "./button"
import { PiAsteriskThin } from "react-icons/pi"
import { sample } from "lodash"

export enum B4BubbleColor {
  WHITE,
  GREEN_NEON,
  GREEN,
  BLUE
}

export enum B4Corner {
  NONE,
  TOP_LEFT,
  TOP_RIGHT,
  BOTTOM_LEFT,
  BOTTOM_RIGHT

}

interface B4BubbleProps {
  children: React.ReactNode,
  color?: B4BubbleColor,
  className?: string,
  sharpCorner?: B4Corner,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  position?: number,
  upperRight?: React.ReactNode
}

const genRadius = (sharpCorner: B4Corner, minimized: boolean): object => {
  if (minimized) {
    return {
      'rounded-tr-[2rem] rounded-br-[2rem] rounded-bl-[2rem]': sharpCorner === B4Corner.TOP_LEFT,
      'rounded-tl-[2rem] rounded-br-[2rem] rounded-bl-[2rem]': sharpCorner === B4Corner.TOP_RIGHT,
      'rounded-tr-[2rem] rounded-tl-[2rem] rounded-br-[2rem]': sharpCorner === B4Corner.BOTTOM_LEFT,
      'rounded-tr-[2rem] rounded-tl-[2rem] rounded-bl-[2rem]': sharpCorner === B4Corner.BOTTOM_RIGHT,
      'rounded-[2rem]': sharpCorner === B4Corner.NONE,
    }
  }

  return {
    'rounded-tr-[3rem] rounded-br-[3rem] rounded-bl-[3rem]': sharpCorner === B4Corner.TOP_LEFT,
    'rounded-tl-[3rem] rounded-br-[3rem] rounded-bl-[3rem]': sharpCorner === B4Corner.TOP_RIGHT,
    'rounded-tr-[3rem] rounded-tl-[3rem] rounded-br-[3rem]': sharpCorner === B4Corner.BOTTOM_LEFT,
    'rounded-tr-[3rem] rounded-tl-[3rem] rounded-bl-[3rem]': sharpCorner === B4Corner.BOTTOM_RIGHT,
    'rounded-[3rem]': sharpCorner === B4Corner.NONE,
  }
}

export const B4Bubble = ({children, color = B4BubbleColor.WHITE, className = null, sharpCorner = B4Corner.TOP_LEFT, onClick = null, position = 0, upperRight = null}: B4BubbleProps) => (
  <div className={clsx('p-6 relative', className, genRadius(sharpCorner, false), {
    'bg-white': color === B4BubbleColor.WHITE,
    'bg-[#46E9A2]': color === B4BubbleColor.GREEN_NEON,
    'bg-b4-mine text-white': color === B4BubbleColor.GREEN,
    'bg-b4-question text-white': color === B4BubbleColor.BLUE,
    'cursor-pointer active:bg-[#ffffff8c]': onClick !== null,
    'bg-opacity-90': position === 1,
    'bg-opacity-80': position === 2,
    'bg-opacity-70': position === 3,
    'bg-opacity-60': position === 4,
    'bg-opacity-50': position === 5,
    'bg-opacity-40': position === 6,
    'bg-opacity-30': position === 7,
    'bg-opacity-20': position >= 8,
  })} onClick={onClick}>
    {upperRight && <div className="absolute right-4 top-4 z-10">{upperRight}</div>}
    {
      children
    }
  </div>
)

interface B4AnswerProps {
  html: string,
  sharpCorner?: B4Corner,
  onClick?: (e: React.MouseEvent<HTMLElement>) => void,
  onReport?: (e: React.MouseEvent<HTMLElement>) => void,
  selected?: boolean,
  mimimized?: boolean
}

export const B4Answer = ({html,  onClick, onReport = null, selected = false, sharpCorner, mimimized = false}: B4AnswerProps) => {
  const radius = genRadius(sharpCorner, mimimized)
  return (
    <div className={clsx('cursor-pointer w-full', radius, {
      'pb-2': !mimimized,
      'pb-1.5': mimimized,
      'bg-b4-secondary [&>div]:bg-gradient-to-b [&>div]:from-[#4966FFE6] [&>div]:to-[#4966FF4D] [&>div]:bg-inherit': selected,
      'bg-[#3723B4] active:bg-b4-secondary [&>div]:active:bg-gradient-to-b [&>div]:active:from-[#4966FFE6] [&>div]:active:to-[#4966FF4D] [&>div]:active:bg-inherit': !selected
    })} onClick={onClick}>
      <div className={clsx('bg-b4-question relative', radius, {
        'p-4': mimimized,
        'p-6': !mimimized
      })}>
        <B4Text className={clsx({'text-sm max-h-10 overflow-y-hidden': mimimized})} html={html} color={B4TextColor.WHITE} innerStyle={{
          wordBreak: 'break-word'
        }}/>
        { onReport && <div className={clsx('absolute', {
          'left-0 bottom-0': sharpCorner === B4Corner.BOTTOM_LEFT,
          'right-0 top-0': sharpCorner === B4Corner.TOP_RIGHT,
        })}>
          <B4IconButtonNoP className="p-2" onClick={e => {
            e.stopPropagation()
            onReport(e)
          }}><PiAsteriskThin className="text-white text-sm"/></B4IconButtonNoP>
        </div> }
      </div>
    </div>
  )
}

const B4AnswerLeft = ({html, onClick, onReport, selected}) => (
  <B4Answer html={html}  onClick={onClick} onReport={onReport} selected={selected} sharpCorner={B4Corner.BOTTOM_LEFT} />
)

const B4AnswerRight = ({html, onClick, onReport, selected}) => (
  <B4Answer html={html} onClick={onClick} onReport={onReport} selected={selected} sharpCorner={B4Corner.TOP_RIGHT} />
)


export enum B4AnswerState {
  IDLE,
  SELECTED_LEFT,
  SELECTED_RIGHT,
  SELECTED_BOTH,
  SKIPPED
}

const COORDS = [
  {x: 50, y: 0},
  {x: 0, y: 100},
  {x: 50, y: 100},
]

export const B4Answers = ({left, right, bothSelected = false, skipped = false, onLeftClick, onRightClick, onLeftReport, onRightReport}) => {
  const [shownLeft, setShownLeft] = useState(left)
  const [shownRight, setShownRight] = useState(right)
  const [componentState, setComponentState] = useState(B4AnswerState.IDLE)

  const [stylesRight, apiRight] = useSpring(
    () => ({
      from: {x: '0%', y: '0%', scale: 1, opacity: 1},
    }),
    []
  )

  const [stylesLeft, apiLeft] = useSpring(
    () => ({
      from: {x: '0%', y: '0%', scale: 1, opacity: 1},
    }),
    []
  )

  // easing: https://easings.net/
  const configIn = {
    duration: 500,
    easing: easings.easeOutQuad
  }
  const configOut = {
    duration: 500,
    easing: easings.easeInQuad
  }

  useChangedEffect(() => {
    const doAnimation = componentState !== B4AnswerState.IDLE // left & right can be switched from outside -> don't do anything
    if (doAnimation) { // left & right can be switched from outside -> don't do anything
      // left or right is switch -> move questions in
      const coord = sample(COORDS)
      apiRight.start({
        to: [
          { x: `${coord.x}%`, y: `${coord.y}%`, scale: 0, opacity: 0.4, config: configOut },
          { x: '0%', y: '0%', scale: 1, opacity: 1, config: configIn },
        ],
      })
      apiLeft.start({
        to: [
          { x: `${-coord.x}%`, y: `${-coord.y}%`, scale: 0, opacity: 0.4, config: configOut },
          { x: '0%', y: '0%', scale: 1, opacity: 1, config: configIn },
        ],
      })
    }

    setTimeout(() => {
      setShownLeft(left)
      setShownRight(right)
      setComponentState(B4AnswerState.IDLE)
    }, doAnimation ? 350 : 0)
    
  }, [left, right, apiRight, apiLeft])

  useChangedEffect(() => {
    if (bothSelected) {
      setComponentState(B4AnswerState.SELECTED_BOTH)
    }
  }, [bothSelected])

  useChangedEffect(() => {
    if (skipped) {
      setComponentState(B4AnswerState.SKIPPED)
    }
  }, [skipped])

  return (
    <div className="space-y-b4-std sm:space-y-0 sm:space-x-b4-std sm:flex">
      <animated.div className="sm:w-1/2 pr-b4-std-2x sm:pr-0" style={stylesLeft}>
        <B4AnswerLeft html={shownLeft} onClick={() => {
          setComponentState(B4AnswerState.SELECTED_LEFT)
          onLeftClick()
        }} onReport={onLeftReport} selected={componentState === B4AnswerState.SELECTED_LEFT || componentState === B4AnswerState.SELECTED_BOTH}/>
      </animated.div>
      <animated.div className="flex justify-end sm:block sm:w-1/2 pl-b4-std-2x sm:pl-0" style={stylesRight}>
        <B4AnswerRight html={shownRight} onClick={() => {
          setComponentState(B4AnswerState.SELECTED_RIGHT)
          onRightClick()
        }} onReport={onRightReport} selected={componentState === B4AnswerState.SELECTED_RIGHT || componentState === B4AnswerState.SELECTED_BOTH}/>
      </animated.div>
    </div>
  )
}